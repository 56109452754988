var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-history" }, [
    _c("div", { staticClass: "table-title" }, [_vm._v("PAYMENTS")]),
    _c(
      "div",
      { staticClass: "payment-table" },
      [
        _vm._m(0),
        _vm._l(_vm.clientPayments, function (payment, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "table-row",
              class: {
                "table-border": index !== _vm.clientPayments.length - 1,
              },
            },
            [
              _c("div", { staticClass: "table-data medium-column" }, [
                _vm._v(_vm._s(payment.purpose)),
              ]),
              _c("div", { staticClass: "table-data medium-column" }, [
                _vm._v("Product Team"),
              ]),
              _c("div", { staticClass: "table-data amount-column" }, [
                _vm._v(
                  "\n        $" +
                    _vm._s(payment.amount.toLocaleString()) +
                    "\n      "
                ),
              ]),
              _c("div", { staticClass: "table-data" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$moment(payment.created_at).format("DD/MMM/YYYY")
                    ) +
                    "\n      "
                ),
              ]),
              _c("div", { staticClass: "table-data last-column" }, [
                _c(
                  "a",
                  { attrs: { href: payment.receipt_url, target: "_blank" } },
                  [_vm._v("View Receipt")]
                ),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "table-head medium-column" }, [
        _vm._v("Details"),
      ]),
      _c("div", { staticClass: "table-head medium-column" }, [
        _vm._v("Recipient"),
      ]),
      _c("div", { staticClass: "table-head" }, [_vm._v("Amount")]),
      _c("div", { staticClass: "table-head" }, [_vm._v("Date")]),
      _c("div", { staticClass: "table-head last-column" }, [_vm._v("Actions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }