<template>
  <div class="payment-cards">
    <div class="title-flex">
      <div class="card-title">PAYMENT CARDS ({{ cardList.length }})</div>
      <!-- <div class="add-card-action">Add Card</div> -->
    </div>
    <div class="card-list-wrapper" v-if="cardList.length != 0">
      <div
        class="card-box"
        v-for="(card, k) in cardList"
        :key="k"
        @mouseover="
          isHover = true;
          cardId = card.id;
        "
        @mouseleave="
          isHover = false;
          cardId = '';
        "
      >
        <div class="icon-selected">
          <img
            v-if="card.default_card"
            src="/img/credit-card/checked.svg"
            alt=""
          />
          <img v-else src="/img/credit-card/unchecked.svg" alt="" />
        </div>

        <div v-if="card.card_brand">
          <img
            v-if="card.card_brand.toUpperCase() == 'MASTERCARD'"
            src="/img/mastercard.svg"
          />
          <img v-else src="/img/visa.svg" />
        </div>
        <div class="card-number">**** {{ card.last4 }}</div>
        <div class="card-actions" v-if="isHover && cardId == card.id">
          <!-- <div class="edit-button">Edit</div>
          <div class="delete-button">Delete</div> -->
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; font-size: 20px">No credit card</div>
  </div>
</template>

<script>
export default {
  props: {
    cardList: {
      required: true,
      type: Array,
    },
  },
  components: {},

  data: () => ({
    isHover: false,
    cardId: 0,
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-cards {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  width: 381px;
  margin-right: 16px;
}
.title-flex {
  display: flex;
  justify-content: space-between;
}
.card-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.add-card-action {
  cursor: pointer;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
}
.card-list-wrapper {
  margin-top: 24px;
}
.card-box {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;
}
.card-number {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 12px;
}
.icon-selected {
  margin-right: 24px;
}
.card-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.delete-button,
.edit-button {
  cursor: pointer;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
}
.delete-button {
  color: #d45b54;
  margin-left: 24px;
}
@media (max-width: 1440px) {
  .payment-cards {
    width: 33%;
  }
}
@media (max-width: 1280px) {
  .payment-cards {
    width: 33%;
  }
}
</style>
