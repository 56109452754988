var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Toolbar", { attrs: { stageTitle: "Payments" } }),
      _vm.cardList == null
        ? _c("Loader")
        : _c("div", { staticClass: "page-container" }, [
            _c(
              "div",
              { staticClass: "client-payment-container" },
              [
                _c("div", { staticClass: "payment-overview-div" }, [
                  _vm.project.workspace_progress_level > 6
                    ? _c("div", { staticClass: "payment-overview" }, [
                        _c("div", { staticClass: "current-charge" }, [
                          _c("div", { staticClass: "box-heading" }, [
                            _vm._v("Current Charge"),
                          ]),
                          _c("div", { staticClass: "current-value-div" }, [
                            _vm._v(
                              "\n              $" +
                                _vm._s(
                                  _vm.currentProjectCharge.toLocaleString()
                                ) +
                                "\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "box-descriptions" }, [
                            _vm._v("Price of Ongoing Iteration"),
                          ]),
                        ]),
                        _c("div", { staticClass: "amount-paid" }, [
                          _c("div", { staticClass: "box-heading" }, [
                            _vm._v("Amount Paid"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "current-value-div current-paid" },
                            [
                              _vm._v(
                                "\n              $" +
                                  _vm._s(_vm.paid.toLocaleString()) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "box-descriptions",
                              staticStyle: { "text-transform": "capitalize" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.checkPaymentPlan(
                                      _vm.project.payment_plan
                                    )
                                  ) +
                                  "\n              Payment Plan\n            "
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "payment-outstanding" }, [
                          _c("div", { staticClass: "box-heading" }, [
                            _vm._v("Outstanding"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "current-value-div current-outstanding",
                            },
                            [
                              _vm._v(
                                "\n              $" +
                                  _vm._s(_vm.outstanding.toLocaleString()) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm.project.payment_plan &&
                          _vm.project.payment_plan !== "iteration"
                            ? _c("div", { staticClass: "box-descriptions" }, [
                                _vm._v(
                                  "\n              Balance is due on\n              "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { display: "block" } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.nextPaymentDate()) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.project.workspace_progress_level <= 6
                    ? _c("div", { staticClass: "payment-overview" }, [
                        _c("div", { staticClass: "current-charge" }, [
                          _c("div", { staticClass: "box-heading" }, [
                            _vm._v("Current Charge"),
                          ]),
                          _c("div", { staticClass: "current-value-div" }, [
                            _vm._v(
                              "\n              $" +
                                _vm._s(
                                  _vm.project.workspace_progress_level <= 4
                                    ? _vm.priorityFee.amount
                                    : _vm.currentProjectCharge.toLocaleString()
                                ) +
                                "\n            "
                            ),
                          ]),
                          _c("div", { staticClass: "box-descriptions" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.project.workspace_progress_level <= 4
                                    ? "Priority List"
                                    : "Product Road map"
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "amount-paid" }, [
                          _c("div", { staticClass: "box-heading" }, [
                            _vm._v("Amount Paid"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "current-value-div current-paid" },
                            [
                              _vm._v(
                                "\n              $" +
                                  _vm._s(
                                    _vm.project.workspace_progress_level <= 4
                                      ? _vm.priorityFee.paid
                                      : _vm.paid.toLocaleString()
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "box-descriptions" }),
                        ]),
                        _c("div", { staticClass: "payment-outstanding" }, [
                          _c("div", { staticClass: "box-heading" }, [
                            _vm._v("Outstanding"),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "current-value-div current-outstanding",
                            },
                            [
                              _vm._v(
                                "\n              $" +
                                  _vm._s(
                                    _vm.project.workspace_progress_level <= 4
                                      ? _vm.priorityFee.outstanding
                                      : _vm.outstanding.toLocaleString()
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "payment-divider" }),
                ]),
                _c(
                  "div",
                  { staticClass: "content-wrapper" },
                  [
                    _c(
                      "ServicePlan",
                      _vm._b({}, "ServicePlan", { project: _vm.project }, false)
                    ),
                    _c(
                      "PaymentCards",
                      _vm._b(
                        {},
                        "PaymentCards",
                        { cardList: _vm.cardList },
                        false
                      )
                    ),
                    _c(
                      "PaymentPlan",
                      _vm._b(
                        {},
                        "PaymentPlan",
                        {
                          projectDoc: _vm.projectDoc,
                          project: _vm.project,
                          checkPaymentPlan: _vm.checkPaymentPlan,
                          amountIterationSubscribed:
                            _vm.amountIterationSubscribed,
                        },
                        false
                      )
                    ),
                  ],
                  1
                ),
                _c(
                  "PaymentHistory",
                  _vm._b(
                    {},
                    "PaymentHistory",
                    { clientPayments: _vm.clientPayments },
                    false
                  )
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }