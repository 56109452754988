<template>
  <div>
    <Toolbar stageTitle="Payments" />
    <Loader v-if="cardList == null" />
    <div class="page-container" v-else>
      <div class="client-payment-container">
        <div class="payment-overview-div">
          <div
            class="payment-overview"
            v-if="project.workspace_progress_level > 6"
          >
            <div class="current-charge">
              <div class="box-heading">Current Charge</div>
              <div class="current-value-div">
                ${{ currentProjectCharge.toLocaleString() }}
              </div>
              <div class="box-descriptions">Price of Ongoing Iteration</div>
            </div>
            <div class="amount-paid">
              <div class="box-heading">Amount Paid</div>
              <div class="current-value-div current-paid">
                ${{ paid.toLocaleString() }}
              </div>
              <div class="box-descriptions" style="text-transform: capitalize">
                {{ checkPaymentPlan(project.payment_plan) }}
                Payment Plan
              </div>
            </div>
            <div class="payment-outstanding">
              <div class="box-heading">Outstanding</div>
              <div class="current-value-div current-outstanding">
                ${{ outstanding.toLocaleString() }}
              </div>
              <div
                class="box-descriptions"
                v-if="
                  project.payment_plan && project.payment_plan !== 'iteration'
                "
              >
                Balance is due on
                <span style="display: block">
                  {{ nextPaymentDate() }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="payment-overview"
            v-if="project.workspace_progress_level <= 6"
          >
            <div class="current-charge">
              <div class="box-heading">Current Charge</div>
              <div class="current-value-div">
                ${{
                  project.workspace_progress_level <= 4
                    ? priorityFee.amount
                    : currentProjectCharge.toLocaleString()
                }}
              </div>
              <div class="box-descriptions">
                {{
                  project.workspace_progress_level <= 4
                    ? "Priority List"
                    : "Product Road map"
                }}
              </div>
            </div>
            <div class="amount-paid">
              <div class="box-heading">Amount Paid</div>
              <div class="current-value-div current-paid">
                ${{
                  project.workspace_progress_level <= 4
                    ? priorityFee.paid
                    : paid.toLocaleString()
                }}
              </div>
              <div class="box-descriptions"></div>
            </div>
            <div class="payment-outstanding">
              <div class="box-heading">Outstanding</div>
              <div class="current-value-div current-outstanding">
                ${{
                  project.workspace_progress_level <= 4
                    ? priorityFee.outstanding
                    : outstanding.toLocaleString()
                }}
              </div>
            </div>
          </div>
          <div class="payment-divider"></div>
        </div>
        <div class="content-wrapper">
          <ServicePlan v-bind="{ project }" />
          <PaymentCards v-bind="{ cardList }" />
          <PaymentPlan
            v-bind="{
              projectDoc,
              project,
              checkPaymentPlan,
              amountIterationSubscribed,
            }"
          />
        </div>
        <PaymentHistory v-bind="{ clientPayments }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import appMixin from "@/mixins/appMixin";
import { completeProjectDetails } from "@/api";
import { clientPaymentCards, getDiscountObject } from "@/api/client";
import Toolbar from "@/components/toolbar/generalToolBar";
import ServicePlan from "./servicePlan";
import PaymentCards from "./paymentCards";
import PaymentPlan from "./paymentPlan";
import PaymentHistory from "./paymentHistory";
import Loader from "@/components/general/centerLoader";

export default {
  mixins: [appMixin],
  props: {},
  components: {
    Toolbar,
    ServicePlan,
    PaymentCards,
    PaymentPlan,
    PaymentHistory,
    Loader,
  },

  data: () => ({
    cardList: null,
    project: null,
    clientPayments: null,
    projectDoc: null,
    paid: 0,
    totalClientPayment: 0,
    amountIterationSubscribed: "",
    outstanding: 0,
    clientDiscount: null,
  }),

  created() {
    let project_id = this.$route.params.id;
    this.fetchProject(project_id);
  },
  methods: {
    fetchProject(projectId) {
      completeProjectDetails(projectId)
        .then((res) => {
          if (res.data.status == 1) {
            let { documents, project, payments } = res.data;
            this.project = project;
            if (project.isDiscountUsed == 1) {
              this.getProjectDiscount(project.discount_id);
            }
            this.clientPayments = payments.reverse();
            this.projectDoc = documents;
            this.clientCreditCard();
            this.addUpPayments();
            this.numberOfIterationSubscribed();
          }
        })
        .catch((err) => {
          this.$developConsole(err, "Error Fetching Project");
        });
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.cardList = response.data.cards;
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
    addUpPayments() {
      let progressLevel = this.project.workspace_progress_level;
      if (progressLevel > 0 && progressLevel < 4) {
        this.clientPayments.forEach((element) => {
          if (element.purpose == "team preview") {
            if (element.iteration_id == this.project.current_iteration) {
              this.totalClientPayment += element.amount;
            }
          }
        });
        this.paid = this.totalClientPayment;
        this.outstanding = this.currentProjectCharge - this.totalClientPayment;
      }
      if (progressLevel >= 4 && progressLevel < 7) {
        this.clientPayments.forEach((element) => {
          if (element.purpose == "project roadmap") {
            if (element.iteration_id == this.project.current_iteration) {
              this.totalClientPayment += element.amount;
            }
          }
        });
        this.paid = this.totalClientPayment;
        this.outstanding = this.currentProjectCharge - this.totalClientPayment;
      }
      if (progressLevel >= 7) {
        this.clientPayments.forEach((element) => {
          if (
            element.purpose !== "project roadmap" &&
            element.purpose !== "team preview"
          ) {
            if (element.iteration_id == this.project.current_iteration) {
              this.totalClientPayment += element.amount;
            }
          }
        });
        this.paid = this.totalClientPayment;
        this.outstanding = this.currentProjectCharge - this.totalClientPayment;
      }
    },

    numberOfIterationSubscribed() {
      let maxIterationSubcrib = this.project.max_subscribed_iteration;
      let maxIterationId =
        maxIterationSubcrib == null
          ? this.projectDoc.iterations[0].id
          : maxIterationSubcrib;
      let index = this.projectDoc.iterations.findIndex(
        (el) => el.id == maxIterationId
      );
      this.amountIterationSubscribed = index + 1;
    },
    checkPaymentPlan(plan) {
      if (plan == "bi-weekly") {
        return "BI-SPRINT";
      } else if (plan == "dual-deposit") {
        return "MID-ITERATION";
      } else {
        return "ONE-TIME";
      }
    },
    nextPaymentDate() {
      let { payment_plan, current_iteration, iteration_cycle } = this.project;
      let { iterations, sprints } = this.projectDoc;
      let sortedSprints = sprints
        .filter((sprint) => sprint.iteration_id == current_iteration)
        .sort((a, b) => a.sprint - b.sprint);
      let completedSprint = sortedSprints.filter(
        (sprint) => sprint.status == 2
      );
      if (payment_plan == "bi-weekly") {
        if (completedSprint.length >= sortedSprints.length - 2) {
          return "Payment completed for this iteration";
        } else {
          for (let i = 0; i < sortedSprints.length; i++) {
            if (
              sortedSprints[i].status == 1 &&
              completedSprint.length < sortedSprints.length - 2
            ) {
              if (sortedSprints[i].sprint % 2 == 0) {
                let returnDate = sortedSprints[i].start_date;
                return this.$moment(returnDate)
                  .add(4, "days")
                  .format("DD/MMM/YYYY");
              } else {
                let returnDate = sortedSprints[i + 1].start_date;
                return this.$moment(returnDate)
                  .add(4, "days")
                  .format("DD/MMM/YYYY");
              }
            }
            // else{
            //   return "No Ongoing Sprint";
            // }
          }
        }
      } else if (payment_plan == "dual-deposit") {
        let sprintIndex = iteration_cycle / 2;

        if (completedSprint.length >= sprintIndex) {
          return "Payment completed for this iteration";
        } else {
          let returnDate = sortedSprints[sprintIndex].start_date;
          return this.$moment(returnDate).add(4, "days").format("DD/MMM/YYYY");
        }
      }
      // else if(payment_plan=='iteration'){
      //  let itearationObj = this.selectedProject.documents.iterations.find(itr=>itr.id==current_iteration);
      //  return  this.formattedDate(itearationObj.due_date.split(" ")[0]);
      // }
    },
    formattedDate(d) {
      let actuallyDate = d.split(" ")[0];
      let day = actuallyDate.split("-")[2];
      let month = actuallyDate.split("-")[1];
      let year = actuallyDate.split("-")[0];
      return `${day}/${month}/${year}`;
    },
    getProjectDiscount(id) {
      getDiscountObject(id)
        .then((response) => {
          this.clientDiscount = response.data.data;
        })
        .catch((error) => {
          this.$developConsole(error, "Error Fetching Project");
        });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    user() {
      return this.currentUser;
    },
    currentProjectCharge() {
      let {
        price: project_price,
        no_of_engineers,
        workspace_progress_level: progressLevel,
      } = this.project;
      let noOfEngineers = no_of_engineers ? no_of_engineers : 1;
      let currentCharge = noOfEngineers * project_price;
      if (progressLevel < 7) {
        currentCharge = currentCharge / 2;
      }
      return currentCharge;
    },
    projectOutStanding() {
      if (this.project.workspace_progress_level >= 6) {
        this.outstanding = this.currentProjectCharge - this.totalClientPayment;
      }
    },
    priorityFee() {
      if (this.project.workspace_progress_level == 1) {
        return { amount: 500, paid: 500, outstanding: 0 };
      } else {
        return { amount: 500, paid: 0, outstanding: 500 };
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container {
  padding: 32px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
}
.payment-overview-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.payment-overview {
  display: flex;
  justify-content: center;
}
.box-heading {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.current-value-div {
  margin-top: 16px;
  font-size: 32px;
  line-height: 38px;
  color: #1b1e22;
}
.box-descriptions {
  margin-top: 16px;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #53585f;
  width: 157px;
}
.box-descriptions span {
  color: #1b1e22;
  font-weight: bold;
}
.payment-outstanding,
.amount-paid {
  margin-left: 104px;
}
.current-paid {
  color: #54cf94;
}
.current-outstanding {
  color: #d45b54;
}
.payment-divider {
  margin: 32px 0% 0px 0%;
  border-top: 1px solid #e4e5e7;
  width: 812px;
}
.content-wrapper {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}
</style>
