var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-plan" }, [
    _vm._m(0),
    _c("div", { staticClass: "card-header-text" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.checkPaymentPlan(_vm.project.payment_plan)) +
          " PAYMENT PLAN\n  "
      ),
    ]),
    _c("div", { staticClass: "plan-description" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.project.payment_plan == "bi-weekly"
              ? _vm.biSprintDescriptions
              : _vm.project.payment_plan == "dual-deposit"
              ? _vm.midItrDescriptions
              : _vm.oneTimeDescriptions
          ) +
          "\n  "
      ),
    ]),
    _c("div", { staticClass: "card-divider" }),
    _c(
      "div",
      { staticClass: "title-flex", staticStyle: { "margin-top": "24px" } },
      [
        _c("div", { staticClass: "card-title" }, [
          _vm._v(
            "\n      ITERATIONS SUBSCRIBED (" +
              _vm._s(_vm.amountIterationSubscribed) +
              ")\n    "
          ),
        ]),
      ]
    ),
    _vm.projectDoc.iterations.length > 0
      ? _c(
          "div",
          { staticClass: "card-list-wrapper" },
          _vm._l(_vm.projectDoc.iterations, function (iteration, index) {
            return index < _vm.amountIterationSubscribed
              ? _c("div", { key: index, staticClass: "card-box" }, [
                  _c("div", { staticClass: "itr-icon-name" }, [
                    _c("div", { staticClass: "itr-icon" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "/img/dashboard/iteration" + (index + 1) + ".svg",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "iteration-name" }, [
                      _vm._v("Iteration " + _vm._s(index + 1)),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "itr-status",
                      style:
                        iteration.status == 1
                          ? "color: #DE9221;"
                          : iteration.status == 0
                          ? "color:#F38A32;"
                          : "",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            iteration.status == 2
                              ? "Completed"
                              : iteration.status == 1
                              ? "Ongoing"
                              : "Pending"
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e()
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-flex" }, [
      _c("div", { staticClass: "card-title" }, [_vm._v("PAYMENT PLAN")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }