<template>
  <div class="payment-service-plan">
    <div class="title-flex">
      <div class="service-title">SERVICE PLAN</div>
      <!-- <div class="change-plan-action">Change</div> -->
    </div>
    <div class="service-plan-details">
      <div class="service-plan-info">
        <div class="selected-plan">{{ project.experience }}</div>
        <!-- <div style="margin-top: 12px" class="selected-plan-info">team of 5</div>
        <div class="selected-plan-info">team of 5</div>
        <div class="selected-plan-info">100 work hours per week</div> -->
        <div class="selected-plan-info">
          {{ project.iteration_cycle }} weeks per iteration
        </div>
        <!-- <div
          style="margin-top: 12px; color: #1b1e22"
          class="selected-plan-info"
        >
          ${{
            (project.price / project.iteration_cycle).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )
          }}
          per weekly sprint
        </div> -->
      </div>
      <div class="service-plan-icon">
        <img src="/img/onboarding/upstart-icon.svg" />
      </div>
    </div>
    <div class="card-divider"></div>
    <div class="title-flex" style="margin-top: 24px">
      <div class="service-title">WORKMODE</div>
      <!-- <div class="change-plan-action">Change</div> -->
    </div>
    <div class="selected-workmode">{{ project.work_mode }}</div>
    <div class="workmode-description">
      Members of the product team work from anywhere in the world
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      required: true,
      type: Object,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-service-plan {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  width: 381px;
  margin-right: 16px;
}
.title-flex {
  display: flex;
  justify-content: space-between;
}
.service-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.change-plan-action {
  cursor: pointer;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
}
.service-plan-details {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.selected-workmode,
.selected-plan {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
  text-transform: capitalize;
}
.selected-workmode {
  margin-top: 24px;
  text-transform: uppercase;
}
.selected-plan-info {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 8px;
  text-align: left;
}
.service-plan-icon img {
  width: 65.94px;
}
.card-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.workmode-description {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 12px;
  text-align: left;
}
@media (max-width: 1440px) {
  .payment-service-plan {
    width: 33%;
  }
}
@media (max-width: 1280px) {
  .payment-service-plan {
    width: 33%;
  }
}
</style>
