<template>
  <div class="payment-history">
    <div class="table-title">PAYMENTS</div>
    <div class="payment-table">
      <div class="table-header">
        <div class="table-head medium-column">Details</div>
        <div class="table-head medium-column">Recipient</div>
        <div class="table-head">Amount</div>
        <div class="table-head">Date</div>
        <div class="table-head last-column">Actions</div>
      </div>
      <div
        class="table-row"
        v-for="(payment, index) in clientPayments"
        :key="index"
        :class="{ 'table-border': index !== clientPayments.length - 1 }"
      >
        <div class="table-data medium-column">{{ payment.purpose }}</div>
        <div class="table-data medium-column">Product Team</div>
        <div class="table-data amount-column">
          ${{ payment.amount.toLocaleString() }}
        </div>
        <div class="table-data">
          {{ $moment(payment.created_at).format("DD/MMM/YYYY") }}
        </div>
        <div class="table-data last-column">
          <a :href="payment.receipt_url" target="_blank">View Receipt</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clientPayments: {
      required: true,
      type: Array,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-history {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  text-align: left;
}
.table-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.table-header {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 24px;
  display: flex;
}
.table-data,
.table-head {
  width: 20%;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
}
.medium-column {
  width: 25%;
  text-transform: capitalize;
}
.last-column {
  text-align: right;
  width: 10%;
}
.table-row {
  padding: 32px 24px;
  display: flex;
}
.table-border {
  border-bottom: 1px solid #e4e5e7;
}
.table-data {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
}
.amount-column {
  color: #1b1e22;
}
</style>
