<template>
  <div class="payment-plan">
    <div class="title-flex">
      <div class="card-title">PAYMENT PLAN</div>
      <!-- <div class="add-card-action">Change</div> -->
    </div>
    <div class="card-header-text">
      {{ checkPaymentPlan(project.payment_plan) }} PAYMENT PLAN
    </div>
    <div class="plan-description">
      {{
        project.payment_plan == "bi-weekly"
          ? biSprintDescriptions
          : project.payment_plan == "dual-deposit"
          ? midItrDescriptions
          : oneTimeDescriptions
      }}
    </div>
    <div class="card-divider"></div>
    <div class="title-flex" style="margin-top: 24px">
      <div class="card-title">
        ITERATIONS SUBSCRIBED ({{ amountIterationSubscribed }})
      </div>
      <!-- <div class="add-card-action">Manage</div> -->
    </div>
    <div class="card-list-wrapper" v-if="projectDoc.iterations.length > 0">
      <div
        class="card-box"
        v-for="(iteration, index) in projectDoc.iterations"
        v-if="index < amountIterationSubscribed"
        :key="index"
      >
        <div class="itr-icon-name">
          <div class="itr-icon">
            <img :src="`/img/dashboard/iteration${index + 1}.svg`" />
          </div>
          <div class="iteration-name">Iteration {{ index + 1 }}</div>
        </div>
        <div
          class="itr-status"
          :style="
            iteration.status == 1
              ? 'color: #DE9221;'
              : iteration.status == 0
              ? 'color:#F38A32;'
              : ''
          "
        >
          {{
            iteration.status == 2
              ? "Completed"
              : iteration.status == 1
              ? "Ongoing"
              : "Pending"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectDoc: {
      required: true,
      type: Object,
    },
    amountIterationSubscribed: {
      required: true,
    },
    checkPaymentPlan: {
      required: true,
      type: Function,
    },
    project: {
      required: true,
      type: Object,
    },
  },
  components: {},

  data: () => ({
    biSprintDescriptions: `Pay an installment every 2 weeks, at the beginning of every 2 sprints`,
    midItrDescriptions: `2 installments. Pay 50% at kick-off, and pay the balance midway through
      the iteration.`,
    oneTimeDescriptions: `Pay per monthly iteration (4 sprints) upfront`,
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-plan {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  width: 381px;
  text-align: left;
}
.title-flex {
  display: flex;
  justify-content: space-between;
}
.card-title {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.add-card-action {
  cursor: pointer;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
}
.card-header-text {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.plan-description {
  margin-top: 12px;
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
}
.card-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.card-list-wrapper {
  margin-top: 24px;
}
.card-box {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
}
.itr-icon-name {
  display: flex;
}
.itr-icon img {
  width: 16px;
  height: 16px;
}
.iteration-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  /* margin-top: 2px; */
  margin-left: 8px;
}
.itr-status {
  font-size: 12px;
  line-height: 14px;
  color: #54cf94;
}
@media (max-width: 1440px) {
  .payment-plan {
    width: 33%;
  }
}
@media (max-width: 1280px) {
  .payment-plan {
    width: 33%;
  }
}
</style>
