var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-service-plan" }, [
    _vm._m(0),
    _c("div", { staticClass: "service-plan-details" }, [
      _c("div", { staticClass: "service-plan-info" }, [
        _c("div", { staticClass: "selected-plan" }, [
          _vm._v(_vm._s(_vm.project.experience)),
        ]),
        _c("div", { staticClass: "selected-plan-info" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.project.iteration_cycle) +
              " weeks per iteration\n      "
          ),
        ]),
      ]),
      _vm._m(1),
    ]),
    _c("div", { staticClass: "card-divider" }),
    _vm._m(2),
    _c("div", { staticClass: "selected-workmode" }, [
      _vm._v(_vm._s(_vm.project.work_mode)),
    ]),
    _c("div", { staticClass: "workmode-description" }, [
      _vm._v(
        "\n    Members of the product team work from anywhere in the world\n  "
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-flex" }, [
      _c("div", { staticClass: "service-title" }, [_vm._v("SERVICE PLAN")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "service-plan-icon" }, [
      _c("img", { attrs: { src: "/img/onboarding/upstart-icon.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "title-flex", staticStyle: { "margin-top": "24px" } },
      [_c("div", { staticClass: "service-title" }, [_vm._v("WORKMODE")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }